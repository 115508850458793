<template>
  <div class="leftbox">
    <div class="topimgbox" @click="gotostatic()">
      <img src="../../../../assets/homeimg/logo.png" alt="">
    </div>
    <div class="leftbox-probably">
      <TitleBox :titlename="'保护区资源概况'" />
      <Switchbox :titlelist="['占地情况','生物资源','生态资源','生态旅游']" @swAreabtn="situationbtn" />
      <div class="ecahrtsbox">
        <!-- <Echartchange class="echarts" :id="monthlyEnergy" :energyData="energyData"
          :SizeOfThe="'1'" /> -->
        <Echartchange class="echarts" :id="monthlyEnergy" :energyData="energyData"
          :totalNumber="totalNumber" />
      </div>
    </div>
    <div class="left-monitoring">
      <TitleBox :titlename="'环境在线监测'" />
      <Switchbox :titlelist="['水质','水文','气象']" @swAreabtn="swAreabtn" />
      <div class="ecahrtsbox">
        <EchartsradarMap class="echarts" :id="radarMap" :sData="sData" />
      </div>
    </div>
    <div class="left-record">
      <TitleBox :titlename="'生物资源在线监测'" />
      <Switchbox :titlelist="['玻璃厂','石矶头','江滩公园']" @swAreabtn="swcoustic" />
      <div class="ecahrtsbox">
        <!-- <EchartsPieChart class="echarts" v-if="flagDb" id="PieCharttwo" :list="biologylist" /> -->
        <Echartchange class="echarts" :id="PieCharttwo" :energyData="biologylist" :SizeOfThe="'300'"
          :totalNumber="totalNumber" />
      </div>
      <!-- <div class="textbox">
        <div class="text">
          <p>豚类采集到 1257</p>
          <p> <i class="el-icon-arrow-up"></i> 同去年环比上涨 2%</p>
        </div>
        <div class="text2">
          <p>鱼类采集到 8463</p>
          <p> <i class="el-icon-arrow-down"></i> 同去年环比下降 10%</p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import api from '../../api'
import axios from 'axios'

import { mapGetters } from 'vuex'
import TitleBox from '../title/index.vue'
import Switchbox from '../title/switch.vue'
import EchartsradarMap from '../echarts/echartsradarMap.vue'
import Echartchange from '../echarts/echartchange.vue'
// import EchartsPieChart from '../echarts/echartsPieChart.vue'
export default {
  props: {},
  components: {
    TitleBox,
    Switchbox,
    EchartsradarMap,
    Echartchange
    // EchartsPieChart
  },
  data () {
    return {
      radarMap: 'radarMap',
      PieCharttwo: 'PieCharttwo',
      monthlyEnergy: 'monthlyEnergy',
      tsdetailNum: {
        nowDataList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        xlist: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      unit: 'll',
      sData: {
        name: ['1月1日', '1月2日'],
        data: [
          [80, 50, 85, 90, 50]
        ],
        typelist: [
          { name: '水温', max: 100 },
          { name: 'PH值', max: 100 },
          { name: '电导率', max: 100 },
          { name: '溶解氧', max: 100 },
          { name: '浊度', max: 100 }
        ]
      },
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区'
      }],
      energyData: [
      ],
      biologylist: [
        {
          name: '豚类',
          value: 1257

        },
        {
          name: '鱼类',
          value: 8463

        }
      ],
      WaterQualitylist: [],
      meteorologicallist: [],
      hydrologicallist: [],
      totalNumber: 0,
      cousticdevId: '220506394700040',
      Finlessnum: 0,
      othernum: 0
    }
  },
  computed: {
    ...mapGetters('zsqy', ['WaterQuality', 'meteorological', 'hydrological'])
  },
  watch: {
    WaterQuality: {
      handler (val, old) {
        this.WaterQualitylist = [val.temp, val.ph, val.ct, val.ddo, val.tur]
        this.sData.data = this.WaterQualitylist
        this.sData.typelist = [
          { name: '水温', max: 100 },
          { name: 'PH值', max: 100 },
          { name: '电导率', max: 100 },
          { name: '溶解氧', max: 100 },
          { name: '浊度', max: 100 }
        ]
      },
      immediate: true,
      deep: true
    },
    meteorological: {
      handler (val, old) {
        this.meteorologicallist = [val.temp, val.windDirection, val.windSpeed, val.rainfall, val.humidity, val.atmosphere]
      },
      immediate: true,
      deep: true
    },
    hydrological: {
      handler (val, old) {
        this.hydrologicallist = [val.speed, val.height, val.flow]
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    swcoustic (index) {
      // console.log(index, 'index')
      if (index === 0) {
        this.cousticdevId = '220506394700040'
      } else if (index === 1) {
        this.cousticdevId = '220506394700026'
      } else if (index === 2) {
        this.cousticdevId = '220506394700028'
      }
      this.pageacoustic(1, this.cousticdevId)
    },
    // 查询水下声学异常图表
    pageacoustic (type, devId) {
      axios.get(this.$constant.zsqyUrl + '/zsqy/waterincident/findByDate?' + 'type=' + type + '&devId=' + devId,
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          // console.log(res, 'this.tableData1')
          this.biologylist[0].value = 0
          this.biologylist[1].value = 0
          res.data.data.ylist.forEach(ele => {
            if (ele.name === '江豚声学识别') {
              this.biologylist[0].name = ele.name
              ele.data.forEach(ele2 => {
                if (ele2) {
                  this.biologylist[0].value = this.biologylist[0].value + ele2
                }
                //  else {
                //   this.biologylist[0].value = 0
                // }
              })
            } else if (ele.name === '其他') {
              this.biologylist[1].name = ele.name
              ele.data.forEach(ele2 => {
                if (ele2) {
                  this.biologylist[1].value = this.biologylist[1].value + ele2
                }
                //  else {
                //   this.biologylist[0].value = 0
                // }
              })
            }
          })
          // console.log(this.biologylist[0].value, this.biologylist[1].value, 'num')
        })
    },
    pageStatistics (index) {
      this.energyData = []
      this.totalNumber = 0
      const obj = {
        categoryId: index
      }
      api.pageStatistics(obj).then(res => {
        // this.footerlist = res.data
        res.data.forEach(ele => {
          this.totalNumber = this.totalNumber + ele.number
          this.energyData.push(
            {
              name: ele.name,
              value: ele.number
            }
          )
        })
        // console.log(this.totalNumber, this.energyData, '-=')
      })
    },
    gotostatic () {
      const href = 'http://183.94.86.131:8001/' // 网站链接
      window.open(href, '_blank')
    },
    swAreabtn (index) {
      if (index === 0) {
        this.sData.data = this.WaterQualitylist
        this.sData.typelist = [
          { name: '水温', max: 100 },
          { name: 'PH值', max: 100 },
          { name: '电导率', max: 100 },
          { name: '溶解氧', max: 100 },
          { name: '浊度', max: 100 }
        ]
      } else if (index === 1) {
        this.sData.data = this.hydrologicallist
        this.sData.typelist = [
          { name: '流速', max: 100 },
          { name: '水深', max: 100 },
          { name: '流量', max: 100 }
        ]
      } else if (index === 2) {
        this.sData.data = this.meteorologicallist
        this.sData.typelist = [
          { name: '温度', max: 100 },
          { name: '风向', max: 360 },
          { name: '风速', max: 100 },
          { name: '降水量', max: 100 },
          { name: '湿度', max: 100 },
          { name: '气压', max: 1000 }
        ]
      }
    },
    situationbtn (index) {
      // console.log(index, 'index')
      this.pageStatistics(index + 1)
    }
  },
  created () {
    this.pageStatistics(1)
    this.pageacoustic(1, '220506394700040')
  }
}
</script>
<style lang="less" scoped>
.leftbox {
  width: 100%;
  height: 100%;
  padding: 20px;
  .echarts {
    width: 90%;
    height: 90%;
  }
  .topimgbox {
    width: 100%;
    height: 7%;
    cursor: pointer;
    img {
      width: 343px;
      height: 42px;
    }
  }
  .leftbox-probably {
    width: 100%;
    height: 31%;
    // background: red;
    .ecahrtsbox {
      width: 100%;
      height: 73%;
      // background: red;
    }
  }
  .left-monitoring {
    width: 100%;
    height: 30%;
    // background: #fff;
    .ecahrtsbox {
      width: 100%;
      height: 73%;
    }
  }

  .left-record {
    width: 100%;
    height: 31%;
    .ecahrtsbox {
      width: 100%;
      height: 73%;
    }
    // .textbox {
    //   width: 100%;
    //   height: 20%;
    //   display: flex;
    //   justify-content: space-between;
    //   color: #fff;
    //   font-family: SourceHanSansCN;
    //   font-weight: 300;
    //   text-align: center;
    //   // background: red;
    //   .text {
    //     width: 49%;
    //     height: 100%;

    //     p {
    //       font-size: 14px;
    //     }
    //     p:nth-child(2) {
    //       color: #00c08b;
    //       font-size: 14px;
    //     }
    //   }
    //   .text2 {
    //     width: 49%;
    //     height: 100%;

    //     p {
    //       font-size: 14px;
    //     }
    //     p:nth-child(2) {
    //       color: #ec4c45;
    //       font-size: 14px;
    //     }
    //   }
    // }
  }
}
</style>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
